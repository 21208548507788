import React from 'react'
import PropTypes from 'prop-types'
import IconPhoneGray from '../../../images/icon_phone_outline_gray.svg'
import { 
  VerifyEvery60SecondsContent 
} from '../../molecules/verifyEvery60SecondsContent'
import { useTranslation } from '../../../context/translationProvider'

import {
  doSendValidationSmsOnVerification,
  doValidateSmsOnVerification,
} from '../../../adapters/auth'
import { toast } from '../../atoms'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { isMobile } from '../../../utils/generic'

export const VerifyPhoneForm = ({ phoneNumber, onSuccess }) => {
  const { translate, lang } = useTranslation()

  if (!lang) return null

  const description = translate(
    'account.verification.phone.description'
  ).replaceAll('{phone}', phoneNumber)
  const smsLength = useFeatureValue('fe_igp_sms_verification_code_length')

  const onResend = async () => {
    const response = await doSendValidationSmsOnVerification(isMobile())

    if (!response.ok) {
      toast.error(translate('verification.phone.sending.error'))
    }
  }

  const onInputFieldDone = async ({ verification_code }) => {
    const response = await doValidateSmsOnVerification(verification_code)

    if (!response.ok) {
      toast.error(translate('verification.phone.verifying.error'))
      return
    }

    toast.success(translate('verification.phone.verifying.success'))
    onSuccess()
  }

  return (
    <VerifyEvery60SecondsContent
      inputFieldNumOfChars={smsLength}
      useResendOnStart
      onResend={onResend}
      icon={IconPhoneGray}
      duration={10}
      onInputFieldDone={onInputFieldDone}
      ctaText={translate('verification.phone.buttonText')}
      title={translate('verification.phone.title')}
      description={description}
      countdownTextTicking={translate('verification.phone.60seconds')}
      countdownTextPaused={translate('verification.phone.clickBelow')}
    />
  )
}

VerifyPhoneForm.propTypes = {
  phoneNumber: PropTypes.string,
  onSuccess: PropTypes.func,
}

VerifyPhoneForm.defaultProps = {
  phoneNumber: '',
  onSuccess: () => {},
}
