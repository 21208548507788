import React, { useContext, useEffect, useState } from 'react'
import { Modal } from '../../atoms/modal'
import { useTranslation } from '../../../context/translationProvider'
import { AuthContext } from '../../../context/authProvider'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'
import { If } from '../../atoms/if'
import { VerifyPhoneForm } from '../VerifyPhoneForm'
import { VerifyEmailForm } from '../VerifyEmailForm'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import useModal from '../../../hooks/useModal'
import { isMobileApp } from '../../../utils/mobileAppFunctionality'

const NOT_VERIFIED = 'NOT_VERIFIED'
const VERIFIED = 'VERIFIED'

export const PhoneAndEmailVerificationModal = () => {
  const { translate } = useTranslation()
  const { safeSetUser, user, requiredData } =
    useContext(AuthContext)

  const { isOpen, closeModal, openModal } = useModal()

  const [isPhoneVerificationFinished, setIsPhoneVerificationFinished] = 
    useState(user?.mobile_verification_status === VERIFIED)

  const isPhoneVerificationStepEnabled = useGroowthbookLocaleFeature(
    'fe_igp_verify_phone_on_login'
  )
  const isEmailVerificationStepEnabled = useGroowthbookLocaleFeature(
    'fe_igp_verify_email_on_login'
  )
  
  const isModalClosable = useFeatureIsOn('fe_kyc_modal_on_login_closable')
  const hideClose = !isModalClosable

  const IS_PHONE_NOT_VERIFIED =
    user?.mobile_verification_status === NOT_VERIFIED

    const SHOULD_SHOW_PHONE_VERIFICATION =
    IS_PHONE_NOT_VERIFIED &&
    !isPhoneVerificationFinished &&
    isPhoneVerificationStepEnabled

  const IS_EMAIL_NOT_VERIFIED = user?.email_verification_status === NOT_VERIFIED

  const SHOULD_SHOW_EMAIL_VERIFICATION =
    IS_EMAIL_NOT_VERIFIED &&
    isPhoneVerificationFinished &&
    isEmailVerificationStepEnabled

  const SHOULD_OPEN_MODAL = 
    SHOULD_SHOW_PHONE_VERIFICATION || SHOULD_SHOW_EMAIL_VERIFICATION

  const fullMobileNumber = `${user?.mobile_prefix} ${user?.mobile_number}`

  const onValidatePhone = async () => {
    setIsPhoneVerificationFinished(true)
    safeSetUser()

    if (!IS_EMAIL_NOT_VERIFIED || !isEmailVerificationStepEnabled) closeModal()
  }

  const onValidateEmail = async () => {
    await safeSetUser()

    closeModal()
  }

  useEffect(() => {
    if (isMobileApp()) return

    if (SHOULD_OPEN_MODAL) {
      openModal()
    }

  }, [SHOULD_OPEN_MODAL])
  
  if (requiredData?.acceptTc === false) return null

  if (!SHOULD_OPEN_MODAL)
    return null

  return (
    <Modal
      theme="form"
      isOpen={isOpen}
      onClose={closeModal}
      title={translate('account.verification.verification')}
      hideClose={hideClose}
    >
      <If
        condition={SHOULD_SHOW_PHONE_VERIFICATION}
        render={() => (
          <VerifyPhoneForm
            phoneNumber={fullMobileNumber}
            onSuccess={onValidatePhone}
          />
        )}
      />

      <If
        condition={SHOULD_SHOW_EMAIL_VERIFICATION}
        render={() => (
          <VerifyEmailForm email={user?.email} onSuccess={onValidateEmail} />
        )}
      />
    </Modal>
  )
}
