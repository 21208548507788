import React from 'react'
import PropTypes from 'prop-types'
import IconEmailGray from '../../../images/icon_email_outline_gray.svg'
import { VerifyEvery60SecondsContent } from '../../molecules/verifyEvery60SecondsContent'
import { useTranslation } from '../../../context/translationProvider'
import { toast } from '../../atoms'
import {
  doLoggedInValidateEmail,
  doSendLoggedInValidationEmail,
} from '../../../adapters/auth'

export const VerifyEmailForm = ({ email, onSuccess }) => {
  const { translate, lang } = useTranslation()

  if (!lang) return null
  
  const description = translate(
    'account.verification.email.description'
  ).replaceAll('{email}', email)

  const onResend = async () => {
    const response = await doSendLoggedInValidationEmail()

    if (!response.ok) {
      toast.error(translate('verification.email.sending.error'))
    }
  }

  const onInputFieldDone = async ({ verification_code }) => {
    const response = await doLoggedInValidateEmail(verification_code)

    if (!response.ok) {
      toast.error(translate('verification.email.verifying.error'))
      return
    }

    toast.success(translate('verification.email.verifying.success'))
    onSuccess()
  }

  return (
    <VerifyEvery60SecondsContent
      useResendOnStart
      onResend={onResend}
      icon={IconEmailGray}
      duration={10}
      onInputFieldDone={onInputFieldDone}
      ctaText={translate('verification.email.buttonText')}
      title={translate('verification.email.title')}
      description={description}
      countdownTextTicking={translate('verification.email.60seconds')}
      countdownTextPaused={translate('verification.email.clickBelow')}
    />
  )
}

VerifyEmailForm.propTypes = {
  email: PropTypes.string,
  onSuccess: PropTypes.func,
}

VerifyEmailForm.defaultProps = {
  email: null,
  onSuccess: () => {},
}
